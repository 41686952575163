import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      mainTitle1: 'With your help I will be able to walk.',
      mainTitle2: 'Thank you from the heart.',
      name: 'Katarina Nobilo',
      about: {
        title: 'A little about me',
        text1:
          'I am Katarina Nobilo from Lumbarda, I am 26 years old and I have been suffering from cerebral palsy since birth.',
        text2:
          'I have been operated on several times in Croatia, and the last time in Germany. I have achieved the best results in treatment with robotic therapy at the Glavić Polyclinic in Dubrovnik, where I have been treated for six years. Health insurance does not cover the therapies, so we had to secure the money for them ourselves. I am now starting to walk with neurological sticks, and I want my walk to improve and be able to at least independently perform hygiene and physiological needs.',
        text3:
          'I have to undergo three surgeries soon at the Aschau Orthopedic Clinic in Germany. The orthopedic doctor who received and examined me at the German clinic gives me a 99% success rate in better and more stable walking and better balance after the surgeries. According to their estimate, these three surgeries will cost 60,000.00 EUR. In addition, the production of orthoses after surgery costs an additional 15,000.00 EUR, and all expenses for traveling three to four times to Germany and the stay of an accompanying person during the treatment period should be added. So the total cost of treatment in Germany, without physical therapies that I would also need to do in Germany, is estimated at 100,000.00 euros.',
        text4: 'Health insurance does not cover the surgeries, so I have to secure the money for them myself.',
        text5:
          'I am engaged in making jewelry, significantly improving the motor skills of my hands in that way, and this is also the way in which I contribute to collecting the necessary money to pay for my therapies and orthopedic procedures in Germany through my own work.',
        text6: 'Thanks to everyone who supports and helps me on the way to my step.',
      },
      donation: {
        title: 'Donation method',
        iban: 'IBAN',
        text: 'PBZ bank with the indication „Za Katin korak“',
      },
    },
  },
  hr: {
    translation: {
      mainTitle1: 'S tvojom pomoći moći ću hodati.',
      mainTitle2: 'Hvala ti od srca.',
      name: 'Katarina Nobilo',
      about: {
        title: 'Malo o meni',
        text1: 'Ja sam Katarina Nobilo iz Lumbarde, imam 26 godina i od rođenja bolujem od cerebralne paralize.',
        text2:
          'Više puta sam operirana u Hrvatskoj, a posljednji put u Njemačkoj. Najbolje rezultate u lječenju postigla sam robotskom terapijom u Poliklinici Glavić u Dubrovniku gdje se liječim već šest godina. Terapije ne plaća zdravstveno osiguranje, pa smo novac za to sami morali osigurati. Sada već pomalo hodam uz neurološke štapove, a želim da moj hod bude sve bolji i da mogu makar samostalno obavljati higijenske i fiziološke potrebe.',
        text3:
          'Uskoro moram izvršiti tri operacije u Ortopedskoj klinici Aschau u Njemačkoj. Liječnik ortoped koji me primio i pregledao u Njemačkoj klinici daje mi 99% uspjeha u boljem i stabilnijem hodanju i boljoj ravnoteži nakon operacija. Ove tri operacije prema njihovoj procjeni koštati će 60.000,00 EUR-a. Uz to izrada ortoza nakon operacija košta dodatnih 15.000,00 EUR-a, a na sve treba dodati i troškove odlaska tri do četiri puta u Njemačku i boravak pratitelja za vrijeme trajanja liječenja. Dakle ukupna cijena liječenja u Njemačkoj, bez fizikalnih terapija koje bi također trebala obaviti u Njemačkoj, procjenjuje se na 100.000,00 eur-a.',
        text4: 'Operacije ne plaća zdravstveno osiguranje, pa novac za to moram osigurati sama.',
        text5:
          'Bavim se izradom nakita i na taj način značajno poboljšavam motoriku ruka, a to je ujedno i način kojim sama svojim radom doprinosim u prikupljanju potrebnog novca za plaćanje mojih terapija i ortopedskih zahvata u Njemačkoj.',
        text6: 'Hvala svima koji me podržavaju i pomažu na putu do mog koraka.',
      },
      donation: {
        title: 'Metoda donacije',
        iban: 'IBAN',
        text: 'PBZ banka s naznakom „Za Katin korak“',
      },
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'hr',

  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export default i18n;
